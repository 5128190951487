import React from 'react';

import Title from '../Title';
import Button from '../Buttons';
import { CaseStudyTag, WorkHeroWrapper } from './styles';

const index = ({
  pageTitle,
  showCaseImage,
  showCaseImageAlt,
  description,
  link,
}) => {
  return (
    <WorkHeroWrapper>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <img src={showCaseImage} alt={showCaseImageAlt} />
          </div>
          <div className="col-lg-6 order-lg-first">
            <CaseStudyTag>Work / Case Study</CaseStudyTag>
            <Title
              title={pageTitle}
              desc={description}
              headingOf="page"
              mblaptop="3rem"
            />
            <Button
              as="a"
              href={link}
              target="_blank"
              rel="noopener noreferrer nofollow"
              btnText="Visit Website"
              btnType="rounded-outline"
            />
          </div>
        </div>
      </div>
    </WorkHeroWrapper>
  );
};

export default index;
