/**
 * Styes for WorkHero
 */

import styled from 'styled-components';
import { device } from '../../layout/device';
import { Wrapper } from '../../layout/global-style';

const WorkHeroWrapper = styled(Wrapper)`
  padding-top: 4rem;

  & img {
    margin-bottom: 3rem;
    max-width: 100%;
    max-height: 540px;
    border-radius: 1rem;
  }

  @media ${device.tablet} {
    p {
      font-size: 1.2rem;
      line-height: 1.7;
    }
  }

  @media ${device.laptop} {
    & img {
      position: absolute;
      width: 50vw;
      max-height: 36rem;
      margin-bottom: 0;
    }

    & cite {
      font-wize: 11px;
    }

    & p {
      max-width: 24rem;
    }

    & .row [class^='col-']:first-of-type {
      position: static;
      display: flex;
      align-items: center;
      min-height: 550px;
    }

    & .row [class^='col-']:last-of-type {
      padding: 1rem 1rem;
    }
  }

  @media ${device.desktopS} {
    p {
      max-width: 100%;
      padding-right: 8rem;
      font-size: 1.25rem;
    }

    & img {
      top: 5rem;
    }
  }

  @media ${device.desktop} {
    & img {
      max-width: 60rem;
    }
  }
`;

const CaseStudyTag = styled.span`
  font-size: 0.825rem;
  padding: 0.5rem 0;
  display: inline-block;
`;

export { WorkHeroWrapper, CaseStudyTag };
