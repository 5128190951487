import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Title from '../Title';
import { v4 as uuidv4 } from 'uuid';

import { WorkTabWrapper } from './style';

const index = ({ processes, processIntro }) => {
  return (
    <WorkTabWrapper>
      <div className="container">
        <Title title="The Process" highlightBorder={false} />
        <div dangerouslySetInnerHTML={{ __html: processIntro }} />
        <div className="work-tabs">
          <Tabs aria-label="Processes" defaultActiveKey="tab1">
            {processes.map((process) => {
              return (
                <Tab
                  key={uuidv4()}
                  title={process.title}
                  eventKey={`tab${process.id}`}
                >
                  <h3>{process.title}</h3>
                  <p dangerouslySetInnerHTML={{ __html: process.desc }} />
                </Tab>
              );
            })}
          </Tabs>
        </div>
      </div>
    </WorkTabWrapper>
  );
};

export default index;
