import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../layout/layout';
import SEO from '../components/SEO';
import Workhero from '../components/Workhero';
import ProjectChallneges from '../components/ProjectChallenges';
import Technologies from '../components/Technologies';
import WorkTabs from '../components/WorkTabs';
import WorkSlider from '../components/WorkSlider';
import Title from '../components/Title';
import { Footnotes, TextPost } from '../layout/global-style';

const CaseStudy = ({ data }) => {
  const post = data.markdownRemark;
  const nextPost = data.allMarkdownRemark;

  return (
    <Layout>
      <SEO
        title={`${post.frontmatter.title} - Case Studies`}
        description={post.excerpt}
      />
      <Workhero
        showCaseImage={post.frontmatter.showCaseImage}
        showCaseImageAlt={post.frontmatter.showCaseImageAlt}
        pageTitle={post.frontmatter.title}
        description={post.frontmatter.intro}
        link={post.frontmatter.website}
      />
      <TextPost className="container">
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <ProjectChallneges challenges={post.frontmatter.challenges} />
        <Technologies
          title="Technologies Used"
          projectTech={post.frontmatter.technology}
        />
        <WorkTabs
          processes={post.frontmatter.processes}
          processIntro={post.frontmatter.processIntro}
        />
        <Title title={post.frontmatter.summaryTitle} highlightBorder={false} />
        <p style={{ maxWidth: '760px' }}>{post.frontmatter.summary}</p>
        <Footnotes>
          <h5>Footnotes:</h5>
          <p>
            Banner image copyright: {post.frontmatter.showCaseImageCopyright}
          </p>
        </Footnotes>
      </TextPost>
      <WorkSlider nextPost={nextPost} />
    </Layout>
  );
};

export default CaseStudy;

export const query = graphql`
  query ($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        date
        title
        showCaseImage
        showCaseImageAlt
        showCaseImageCopyright
        companyLogo
        keywords
        technology
        website
        intro
        challenges
        processIntro
        processes {
          id
          title
          desc
        }
        summaryTitle
        summary
      }
    }
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/case-studies/.*.md$/" }
        fields: { slug: { ne: $slug } }
      }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            title
            shortTitle
            showCaseImage
            showCaseImageAlt
            showCaseImageCopyright
            companyLogo
            keywords
            technology
            website
            intro
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

CaseStudy.propTypes = {
  data: PropTypes.object,
};
