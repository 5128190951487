import styled from 'styled-components';
import { device } from '../../layout/device';
import { Wrapper } from '../../layout/global-style';

export const WorkSliderWrapper = styled(Wrapper)`
  padding-top: 8rem;
  padding-bottom: 18rem;

  .slick-slide {
    padding: 0 2rem;
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;
  }

  @media ${device.tablet} {
    .slick-prev {
      left: -1.5rem;
    }
    .slick-next {
      right: -1.5rem;
    }
  }

  @media ${device.laptop} {
    .slick-slide {
      padding: 0rem;
    }

    .slick-prev {
      left: -3.5rem;
    }
    .slick-next {
      right: -3.5rem;
    }
  }
`;
