import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { ThemeProvider } from 'styled-components';

import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

import Title from '../Title';
import { WorkSliderWrapper } from './style';
import WorkCard from '../Work/WorkCard';

const SlideTheme = {
  flexdir: 'row-reverse',
};

const index = ({ nextPost }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <WorkSliderWrapper>
      <div className="container">
        <Title title="Next Project" align="center" />
        <ThemeProvider theme={SlideTheme}>
          <Slider {...settings}>
            {nextPost.edges.map((work) => (
              <WorkCard
                key={uuidv4()}
                img={work.node.frontmatter.showCaseImage}
                logo={work.node.frontmatter.companyLogo}
                caption={work.node.frontmatter.keywords}
                title={work.node.frontmatter.shortTitle}
                caseStudy={`/case-studies${work.node.fields.slug}`}
                website={work.node.frontmatter.website}
                masonLayout={true}
              />
            ))}
          </Slider>
        </ThemeProvider>
      </div>
    </WorkSliderWrapper>
  );
};

WorkCard.propTypes = {
  img: PropTypes.string,
  logo: PropTypes.string,
  caption: PropTypes.string,
  title: PropTypes.string,
  btnType: PropTypes.string,
  btnText: PropTypes.string,
  caseStudy: PropTypes.string,
  masonLayout: PropTypes.bool,
};

export default index;
