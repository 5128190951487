import styled from 'styled-components';
import colors from '../../layout/globalColors';
import { device } from '../../layout/device';
import { Wrapper } from '../../layout/global-style';

export const WorkTabWrapper = styled(Wrapper)`
  .work-tabs {
    display: flex;
    flex-direction: column;

    .nav-tabs {
      border-bottom: none;
    }

    nav {
      flex-direction: row;
      justify-content: space-between;
    }

    .nav-link:not(.active),
    .nav-link.active {
      color: ${colors.cadetBlue};
    }

    @media ${device.laptop} {
      flex-direction: row;

      nav {
        width: 35%;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 3rem;
      }

      .tab-content {
        width: 65%;
        padding-left: 8rem;
      }
    }
  }

  .nav-link {
    position: relative;
    padding: 1.5rem 0;
    font-size: 0.8rem;
    font-weight: 500;

    &.active::before {
      content: '';
      width: 0.6rem;
      height: 0.6rem;
      background: ${colors.secondary};
      display: flex;
      border-radius: 0.6rem;
      position: absolute;
      left: 50%;
      bottom: 0.8rem;
      transform: translateX(-50%);
      transition: all 0.3s ease-in;
    }
  }

  @media ${device.tablet} {
    .nav-link {
      font-size: 1.2rem;
    }

    .nav-link ::before {
      content: '';
      width: 0.6rem;
      height: 0.6rem;
      background: ${colors.secondary};
      display: flex;
      border-radius: 0.6rem;
      position: absolute;
      left: -3rem;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s ease-in;
    }

    .nav-link.active::before {
      bottom: auto;
      left: -3rem;
      top: 50%;
      transform: translateY(-50%);
      height: 3rem;
    }
  }

  @media ${device.laptop} {
    .nav-link::before {
      left: -3rem;
    }

    .nav-link.active::before {
      left: -3rem;
    }
  }
`;
