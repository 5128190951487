import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import Title from '../Title';
import { device } from '../../layout/device';
import { Wrapper } from '../../layout/global-style';

const ChallengeWrapper = styled(Wrapper)`
  .challenge-inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4rem;

    @media ${device.laptop} {
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }
  }

  .imgBox {
    height: 100%;
  }

  img {
    height: 100%;
    object-fit: cover;
    border-radius: 1rem;
  }

  ul li {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: #f8f8f8;
    position: relative;
    line-height: 1.8;
  }

  @media ${device.mobile} {
    ul li {
      font-size: 1.1rem;
    }

    ul li::before {
      left: -1.6rem;
    }
  }

  @media ${device.lapoptL} {
    ul li {
      font-size: 1.2rem;
    }
  }
`;

const index = ({ challenges }) => {
  return (
    <ChallengeWrapper>
      <div className="challenge-inner">
        <div className="imgBox">
          <img
            src="/img/optimum/project-challenges.jpg"
            alt="Project challenges team discussion"
          />
        </div>
        <div className="challengebox">
          <Title title="Project Challenges" highlightBorder={false} />
          <ul>
            {challenges.map((challenge) => {
              return <li key={uuidv4()}> {challenge} </li>;
            })}
          </ul>
        </div>
      </div>
    </ChallengeWrapper>
  );
};

export default index;
